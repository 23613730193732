// 发现
export const downloadType_Found = 1

// 数据查询 - 参训率
export const downloadType_Statistics_Participation_Student_Finish = 2
export const downloadType_Statistics_Participation_Student_Exam = 3
export const downloadType_Statistics_Participation_Student_Participate = 4

// 数据查询 - 话术
export const downloadType_Statistics_SpeechSkill_Skill_Student = 5
export const downloadType_Statistics_SpeechSkill_Check_Student = 6

// 数据查询 - 证书
export const downloadType_Statistics_Certificate_Online = 7
export const downloadType_Statistics_Certificate_Offline = 8

// 直播数据
export const downloadType_DataStatistics_Broadcast_Flow_Detail_User = 10

// 线下培训
export const downloadType_Offline_Detail_Sign = 9
export const downloadType_Offline_Detail_Check = 11
export const downloadType_Offline_Detail_Exam = 12
export const downloadType_Offline_Detail_Live = 13
export const downloadType_Offline_Detail_Homework = 14
export const downloadType_Offline_Detail_Student = 15
export const downloadType_Offline_Detail_Summary = 16
export const downloadType_Offline_Detail_Teacher = 17


export const downloadTypeList = [
  {
    id: downloadType_Found,
    key: downloadType_Found,
    name: '发现'
  },

  {
    id: downloadType_Statistics_Participation_Student_Finish,
    key: downloadType_Statistics_Participation_Student_Finish,
    name: '数据查询-学员明细-完训统计'
  },
  {
    id: downloadType_Statistics_Participation_Student_Exam,
    key: downloadType_Statistics_Participation_Student_Exam,
    name: '数据查询-学员明细-考试统计'
  },
  {
    id: downloadType_Statistics_Participation_Student_Participate,
    key: downloadType_Statistics_Participation_Student_Participate,
    name: '数据查询-学员明细-参训统计'
  },

  {
    id: downloadType_Statistics_SpeechSkill_Skill_Student,
    key: downloadType_Statistics_SpeechSkill_Skill_Student,
    name: '数据查询-话术查询-学员数据'
  },
  {
    id: downloadType_Statistics_SpeechSkill_Check_Student,
    key: downloadType_Statistics_SpeechSkill_Check_Student,
    name: '数据查询-检核查询-学员数据'
  },

  {
    id: downloadType_Statistics_Certificate_Online,
    key: downloadType_Statistics_Certificate_Online,
    name: '数据查询-证书查询-线上任务证书'
  },
  {
    id: downloadType_Statistics_Certificate_Offline,
    key: downloadType_Statistics_Certificate_Offline,
    name: '数据查询-证书查询-线下培训证书'
  },

  {
    id: downloadType_DataStatistics_Broadcast_Flow_Detail_User,
    key: downloadType_DataStatistics_Broadcast_Flow_Detail_User,
    name: '直播统计-人员明细'
  },

  {
    id: downloadType_Offline_Detail_Sign,
    key: downloadType_Offline_Detail_Sign,
    name: '线下培训-培训详情-报名/结业统计'
  },
  {
    id: downloadType_Offline_Detail_Check,
    key: downloadType_Offline_Detail_Check,
    name: '线下培训-培训详情-考勤打卡统计'
  },
  {
    id: downloadType_Offline_Detail_Exam,
    key: downloadType_Offline_Detail_Exam,
    name: '线下培训-培训详情-考试成绩统计'
  },
  {
    id: downloadType_Offline_Detail_Live,
    key: downloadType_Offline_Detail_Live,
    name: '线下培训-培训详情-直播统计'
  },
  {
    id: downloadType_Offline_Detail_Homework,
    key: downloadType_Offline_Detail_Homework,
    name: '线下培训-培训详情-作业统计'
  },
  {
    id: downloadType_Offline_Detail_Student,
    key: downloadType_Offline_Detail_Student,
    name: '线下培训-培训详情-学员提交材料'
  },
  {
    id: downloadType_Offline_Detail_Teacher,
    key: downloadType_Offline_Detail_Teacher,
    name: '线下培训-培训详情-讲师提交材料'
  },
  {
    id: downloadType_Offline_Detail_Summary,
    key: downloadType_Offline_Detail_Summary,
    name: '线下培训-培训详情-培训结果统计'
  }
]

