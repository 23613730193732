<template>
  <div>
    <div class="flex flex-end">
      <app-select-base v-model="type" :list="downloadTypeList" mode="default" placeholder="请选择下载类型" />
      <a-button type="primary" class="ml-10" @click="onSearch()">搜索</a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="mt-20 table small-cell-table">

      <template slot="status" slot-scope="text">
        <span class="status-tag" :class="{['status-' + text]: true}">
          {{ ['审批中', '审批通过', '被驳回', '已取消'][text] }}
        </span>
      </template>

      <template slot="action" slot-scope="text, record">
        <a-button v-if="[1].includes(record.status)"
                  type="link"
                  size="small"
                  @click="onDownload(record)">
          下载
        </a-button>
        <a-button v-if="[0].includes(record.status) && false"
                  desc="暂无取消功能"
                  type="link"
                  size="small"
                  class="danger"
                  @click="onCancel(record)">
          取消
        </a-button>

        <a-popover placement="topLeft" trigger="click">
          <template slot="content">
            <div>{{ record.reasons_dismissal || '--' }}</div>
          </template>
          <a-button v-if="[2].includes(record.status)"
                    type="link"
                    size="small">
            查看原因
          </a-button>
        </a-popover>
        <a-button v-if="[2].includes(record.status)"
                  type="link"
                  size="small"
                  class="danger"
                  @click="onRetry(record)">
          再次提交
        </a-button>

        <span v-if="[0, 3].includes(record.status)">--</span>

      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange" />
      </template>
    </a-table>

    <DownloadReasonModal ref="downloadReasonModalRef" @saved="onPageChange(pagination.current, pagination.pageSize)" />
  </div>
</template>

<script>
import { getDownloadRecord } from './api'
import { downloadTypeList } from './consts'

import Pagination, { getPagination, updatePagination } from '@components/Pagination.vue'

import DownloadReasonModal from './components/DownloadReasonModal.vue'


const columns = [
  {
    width: '260px',
    align: 'center',
    title: '文件名',
    key: 'filename',
    dataIndex: 'filename'
  },
  {
    width: '160px',
    align: 'center',
    title: '时间',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
    width: '180px',
    align: 'center',
    title: '申请原因',
    key: 'reason_application',
    dataIndex: 'reason_application'
  },
  {
    width: '100px',
    align: 'center',
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: '180px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'List',
  components: {
    DownloadReasonModal,
    Pagination
  },
  data() {
    return {
      loading: false,
      downloading: false,

      downloadTypeList,

      type: '',

      retryModalShown: false,

      columns,
      data: [],
      pagination: { ...getPagination() }
    }
  },
  created() {
    this.onSearch()
  },
  methods: {
    onReset() {
      this.type = ''
      this.onSearch()
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize)
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize)
    },
    onSizeChange(current, size) {
      this.getData(1, size)
    },
    async getData(page, pageSize) {
      page = page || 1
      pageSize = pageSize || this.pagination.defaultPageSize

      if(this.loading) {
        return
      }
      this.loading = true

      const params = {
        type: this.type,
        page,
        per_page: pageSize
      }
      delete params.date

      const data = await getDownloadRecord(params).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        return
      }
      this.data = data?.data || []

      const pagination = data?.meta?.pagination || {}
      this.pagination = updatePagination(this.pagination, pagination)
    },

    onDownload(record) {
      if(!record?.file_url) {
        this.$message.error('未找到该下载地址')
        return
      }

      window.open(record.file_url, '_self')
      this.$message.success('下载成功')
    },

    onRetry(record) {
      this.$refs.downloadReasonModalRef.show({
        id: record.id,
        disabledFormat: true,
        down_type: 1,
        reason_application: record.reason_application,
        params: record
      })
    }
  }
}
</script>

<style scoped lang="scss">
.status-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;

  &.status-0 {
    color: #2F54EB;
    border-color: #ADC6FF;
    background-color: #F0F5FF;

  }

  &.status-1 {
    color: #52C41A;
    border-color: #B7EB8F;
    background-color: #F6FFED;
  }

  &.status-2 {
    color: #F5222D;
    border-color: #FFA39E;
    background-color: #FFF1F0;
  }

  &.status-3 {
    color: #ABABAB;
    border-color: #DCDFE6;
    background-color: #F5F5F5;
  }
}

.danger {
  color: #CF1322;
}
</style>
